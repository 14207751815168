import { firebaseApp } from "@/firebase"
import store from "@/store"
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore"
const db = getFirestore(firebaseApp)

async function createGameSession(data, emaillist) {
  const { quizid } = data
  const quizpin = Math.floor(100000 + Math.random() * 900000).toString()
  const session = {
    createdAt: new Date(),
    index: null,
    end: false,
    started: false,
    enablequestion: true,
    futuretime: 0,
    quizpin: quizpin,
    showanswer: false,
    showleader: false,
    emaillist: emaillist?.replace(/\s/g, "").split(","),
  }
  const sessionref = doc(collection(db, "quizes", quizid, "sessions"))
  await setDoc(doc(db, "activesessions", quizpin), { quizpin: quizpin, quizid: quizid, sessionid: sessionref.id })
  const sessionData = { ...session, sessionid: sessionref.id, ...data }
  await setDoc(sessionref, sessionData)
  return `${quizid}-${sessionref.id}-${quizpin}`
}

async function getGameSession(quizid, sessionid) {
  const unsubscribe = onSnapshot(doc(db, "quizes", quizid, "sessions", sessionid), (document) => {
    store.dispatch("bindGameSession", document.data())
  })
  store.commit("SET_GAME_SESSION_LISTENER", unsubscribe)
}

async function getParticipantSession(quizid, sessionid, participantsessionid) {
  const unsubscribe = onSnapshot(
    doc(db, "quizes", quizid, "sessions", sessionid, "participantsessions", participantsessionid),
    (document) => {
      store.dispatch("bindParticipantSession", document.data())
    }
  )
  store.commit("SET_PARTICIPANT_SESSION_LISTENER", unsubscribe)
}

async function getParticipantList(quizid, sessionid) {
  const participantsessionref = collection(db, "quizes", quizid, "sessions", sessionid, "participantsessions")
  const q = query(participantsessionref, orderBy("totalscore", "desc"))
  const unsubscribe = onSnapshot(q, (qs) => {
    var participants = []
    qs.forEach((document) => {
      participants.push({ ...document.data(), id: document.id })
    })
    store.dispatch("bindParticipantList", participants)
  })
  store.commit("SET_PARTICIPANT_LIST_LISTENER", unsubscribe)
}

async function updateGameSession(session, details) {
  await setDoc(doc(db, "quizes", session.quizid, "sessions", session.sessionid), details, { merge: true })
  store.dispatch("bindGameSession", session)
}

async function deleteGameSession(session) {
  await deleteDoc(doc(db, "quizes", session.quizid, "sessions", session.sessionid))
}

async function deleteActiveSession(session) {
  await deleteDoc(doc(db, "activesessions", session.quizpin.toString()))
  store.commit("CLEAR_GAME_DATA")
}

async function checkActiveSession(pin) {
  const docSnap = await getDoc(doc(db, "activesessions", pin.toString()))
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    throw new Error("Incorrect Pin or Game Does not exist")
  }
}

async function createParticipantSession(result, name, color) {
  const { quizid, sessionid } = result
  console.log(quizid, sessionid)
  const participantsessionref = doc(collection(db, "quizes", quizid, "sessions", sessionid, "participantsessions"))
  const participantSessionDetails = {
    name: name,
    totalscore: 0,
    details: [],
    index: 0,
    color: color,
    participantsessionid: participantsessionref.id,
    sessionid: result.sessionid,
    quizid: result.quizid,
    lastselected: {
      answer: "",
      isRight: false,
    },
    powerup: "",
    powerupid: 999,
    powerupcount: 0,
    showanswer: false,
  }
  await setDoc(participantsessionref, participantSessionDetails, { merge: true })
  return `${result.quizid}-${result.sessionid}-${participantsessionref.id}`
}

async function updateParticipantSession(r, details, answerData) {
  const res = r.split("-")
  const [quizid, sessionid, participantsessionid] = res
  if (answerData) {
    await setDoc(
      doc(db, "quizes", quizid, "sessions", sessionid, "participantsessions", participantsessionid),
      { ...details, details: arrayUnion(answerData) },
      { merge: true }
    )
    store.dispatch("bindParticipantSession", { ...details })
    store.dispatch("addParticipantAnswer", answerData)
  } else {
    await setDoc(
      doc(db, "quizes", quizid, "sessions", sessionid, "participantsessions", participantsessionid),
      details,
      {
        merge: true,
      }
    )
    store.dispatch("bindParticipantSession", details)
  }
}

export {
  getGameSession,
  getParticipantSession,
  getParticipantList,
  deleteActiveSession,
  deleteGameSession,
  createParticipantSession,
  updateParticipantSession,
  checkActiveSession,
  createGameSession,
  updateGameSession,
}
